import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Field, formValueSelector, reduxForm, change, submit,
} from 'redux-form';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import renderSelectField from '../../form/Select';
import { concatenarNombreAlumno, findLabelByLanguage } from '../../../../utils/stringUtils';

class AlumnoForm extends PureComponent {
  componentDidMount() {
    /* eslint-disable-next-line no-shadow */
    const { change, itemId } = this.props;
    if (itemId) {
      this.handleAlumnoChange(itemId);
    } else {
      change('alumnoDeLista', null);
    }
  }

  handleAlumnoChange = (id) => {
    /* eslint-disable no-shadow */
    const {
      change, nucleoAlumnos, catalogo, language, t,
    } = this.props;
    if (id !== 'new') {
      const label = findLabelByLanguage(nucleoAlumnos.parentesco, catalogo.parentesco);
      change('parentesco', {
        value: nucleoAlumnos.rolAlumno,
        label: label ? label[language] : t('Desconocido'),
      });
    } else {
      change('parentesco', null);
    }
  }

  required = (value) => {
    const { t } = this.props;
    if (!value) {
      return t('Obligatorio');
    }
    return undefined;
  }

  render() {
    const {
      handleSubmit, t, formValues, onCancel, catalogo, language, nucleoAlumnos, itemId, currentValue,
    } = this.props;

    const {
      parentesco,
    } = catalogo;

    let alumnosSelect = nucleoAlumnos.filter(na => _.map(currentValue, 'alumno').indexOf(na.id) === -1);
    alumnosSelect = alumnosSelect.filter((as) => as.nivel !== "Egresado");

    return (
      <form className="form" onSubmit={handleSubmit}>
        {
          !itemId
          && (
            <div className="form__form-group">
              <span className="form__form-group-label">{t('Seleccione el alumno a vincular')}</span>
              <div className="form__form-group-field">
                <Field
                  name="alumnoDeLista"
                  id="alumnoDeLista"
                  component={renderSelectField}
                  options={alumnosSelect.map(nucleoAlumno => (
                    {
                      label: concatenarNombreAlumno(nucleoAlumno),
                      value: nucleoAlumno.id,
                    }
                  ))
                  }
                  validate={this.required}
                />
              </div>
            </div>
          )
        }
        {
          formValues && formValues.alumnoDeLista
          && (
            <React.Fragment>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('Parentesco con el tarjetahabiente')}</span>
                <div className="form__form-group-field">
                  <Field
                    name="parentesco"
                    id="parentesco"
                    component={renderSelectField}
                    options={parentesco.map(tipo => (
                      {
                        label: tipo[language],
                        value: tipo.es,
                      }
                    ))
                    }
                    validate={this.required}
                  />
                </div>
              </div>
            </React.Fragment>
          )
        }
        <button
          onClick={(evt) => {
            evt.preventDefault();
            const { dispatch } = this.props;
            dispatch(submit('alumno'));
          }}
          type="submit"
          style={{ marginTop: '20px' }}
          className="btn btn-primary modal-form__btn modal-form__btn--small"
        >
          {t('Guardar y Vincular')}
        </button>
        <button
          onClick={onCancel}
          type="button"
          className="btn btn-danger modal-form__btn modal-form__btn--small"
        >
          {t('Cancelar')}
        </button>
      </form>
    );
  }
}

AlumnoForm.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  formValues: PropTypes.shape({}),
  change: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  catalogo: PropTypes.shape({}).isRequired,
  language: PropTypes.string.isRequired,
  nucleoAlumnos: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  itemId: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  currentValue: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

AlumnoForm.defaultProps = {
  formValues: {
    alumno: '',
  },
  itemId: '',
};

const selector = formValueSelector('alumno');

function mapStateToProps(state) {
  const formValues = selector(state,
    'alumnoDeLista', 'alumno');
  return { formValues };
}

const mapDispatchToProps = dispatch => (
  bindActionCreators({ change }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'alumno',
})(withTranslation('common')(AlumnoForm)));
