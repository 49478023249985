import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { concatenarNombreAlumno } from "../../../utils/stringUtils";
import { CICLO_LECTIVO_A_MATRICULAR } from "../../../utils/constants";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";

const TarjetaAlumno = ({
  t,
  alumno,
  isAdmin,
  puedeActualizar,
  onChangePaymentStatus,
  onChangeContractStatus,
  onToggleStudentStatus,
  puedeCambiarEstadoMatricula,
  puedeCambiarEstadoPago,
  puedeCambiarEstadoContrato,
  loadingSuccessContractChange,
}) => {
  const [openModal, setOpenModal] = React.useState(false);

  return (
    <Row>
      <Col md={12}>
        <Card>
          <CardBody>
            <Dialog
              onBackdropClick={() => {
                setOpenModal(false);
              }}
              open={openModal}
            >
              <DialogTitle
                onClose={() => {
                  setOpenModal(false);
                }}
              >
                {t("Confirmar Matrícula")}
              </DialogTitle>
              <DialogContent dividers>
                <p>
                  ¿Está seguro que desea confirmar el contrato como recibido?
                  Esta acción no se puede deshacer.
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    onClick={() => {
                      onChangeContractStatus(alumno);
                      setOpenModal(false);
                    }}
                    size="sm"
                    style={{
                      marginTop: "20px",
                      marginRight: "10px",
                    }}
                    color="success"
                  >
                    {t("Confirmar")}
                  </Button>
                  <Button
                    onClick={() => {
                      setOpenModal(false);
                    }}
                    size="sm"
                    style={{
                      marginTop: "20px",
                      marginRight: "10px",
                    }}
                    color="danger"
                  >
                    {t("Cancelar")}
                  </Button>
                </div>
              </DialogContent>
            </Dialog>
            <div className="card__title">
              <h4 className="bold-text">{concatenarNombreAlumno(alumno)}</h4>
            </div>
            {alumno.pendientePago && (
              <p className="text-danger bold-text">
                {t(
                  "Pendiente de pago, por favor comunicarse al correo electrónico"
                )}{" "}
                <a href="mailto:asistente.contable@weizmancr.net">
                  asistente.contable@weizmancr.net
                </a>
              </p>
            )}
            <p>
              <span className="bold-text">{t("Identificación")}</span>:{" "}
              {alumno.identificacion
                ? alumno.identificacion
                : t("No registrada")}
            </p>
            <p>
              <span className="bold-text">{t("Fecha de Nacimiento")}</span>:{" "}
              {alumno.fechaNacimiento
                ? new Date(alumno.fechaNacimiento).toLocaleDateString("es")
                : t("No registrada")}
            </p>
            <p>
              <span className="bold-text">
                {alumno.estadoMatricula === "Pendiente"
                  ? t("Grado por matricular")
                  : t("Grado matriculado")}
              </span>
              : {t(alumno.nivel)}
            </p>
            <p>
              <span className="bold-text">{t("Estado")}</span>:{" "}
              {alumno.inactivo ? t("Inactivo") : t(alumno.estadoMatricula)}
            </p>
            {
              alumno.nivel !== "Egresado" ?
                <>
                  {alumno.estadoMatricula !==
                    `Matrícula ${CICLO_LECTIVO_A_MATRICULAR} Completa` &&
                    !isAdmin &&
                    alumno.nivel !== "Egresado" &&
                    !alumno.pendientePago ? (
                    <Link to={`/matricula/${alumno.id}/matricular`}>
                      <Button
                        size="sm"
                        style={{
                          marginTop: "20px",
                          marginRight: "10px",
                        }}
                        color="success"
                      >
                        {alumno.estadoMatricula ===
                          "Pendiente Contrato Servicios Educativos"
                          ? t("Descargar Contrato")
                          : `+ ${t("Matricular")}`}
                      </Button>
                    </Link>
                  ) : (
                    (isAdmin || (!isAdmin && !alumno.pendientePago)) && (
                      <React.Fragment>
                        <Link to={`/alumno/${alumno.id}/actualizar`}>
                          <Button
                            size="sm"
                            style={{
                              marginTop: "20px",
                              marginRight: "10px",
                            }}
                            color="primary"
                          >
                            {puedeActualizar ? t("Actualizar Datos") : t("Ver Datos")}
                          </Button>
                        </Link>
                        {/*alumno.estadoMatricula === `Matrícula ${CICLO_LECTIVO_A_MATRICULAR} Completa`
                        && (
                          <Link
                            to={`/matricula/${alumno.id}/ver`}
                          >
                            <Button
                              size="sm"
                              style={{
                                marginTop: '20px',
                                marginRight: '10px',
                              }}
                              color="primary"
                            >
                              {t('Ver Constancia')}
                            </Button>
                          </Link>
                        )*/}
                      </React.Fragment>
                    )
                  )}
                  {alumno.nivel !== "Egresado" && (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://portal-weizman.s3.amazonaws.com/utiles-${encodeURI(
                        alumno.nivel
                      )}.pdf`}
                    >
                      <Button
                        size="sm"
                        style={{
                          marginTop: "20px",
                          marginRight: "10px",
                        }}
                        color="primary"
                      >
                        {t("Ver Lista de Útiles")}
                      </Button>
                    </a>
                  )}
                  {alumno.dayCare && (
                    <Link
                      style={{ marginLeft: "10px" }}
                      to={`/matricula/${alumno.id}/ver-day-care`}
                    >
                      <Button
                        size="sm"
                        style={{
                          marginTop: "20px",
                          marginRight: "10px",
                        }}
                        color="primary"
                      >
                        {t("Ver Matricula Day Care")}
                      </Button>
                    </Link>
                  )}
                  {puedeCambiarEstadoPago && (
                    <Button
                      onClick={() => {
                        onChangePaymentStatus(alumno.id, !alumno.pendientePago);
                      }}
                      size="sm"
                      style={{
                        marginTop: "20px",
                      }}
                      color="primary"
                    >
                      {alumno.pendientePago
                        ? t("Desmarcar como pendiente de pago")
                        : t("Marcar como pendiente de pago")}
                    </Button>
                  )}
                  {puedeCambiarEstadoMatricula && (
                    <Button
                      onClick={() => {
                        onToggleStudentStatus(alumno.id, !alumno.inactivo);
                      }}
                      size="sm"
                      style={{
                        marginTop: "20px",
                      }}
                      color={alumno.inactivo ? "success" : "danger"}
                    >
                      {alumno.inactivo
                        ? t("Activar alumno")
                        : t("Inactivar alumno")}
                    </Button>
                  )}
                  {puedeCambiarEstadoContrato &&
                    alumno.estadoMatricula ===
                    "Pendiente Contrato Servicios Educativos" && (
                      <Button
                        onClick={() => {
                          setOpenModal(true);
                        }}
                        size="sm"
                        style={{
                          marginTop: "20px",
                        }}
                        color="success"
                        disabled={loadingSuccessContractChange}
                      >
                        {t("Marcar contrato como recibido")}
                      </Button>
                    )}
                  {isAdmin &&
                    alumno.estadoMatricula ===
                    "Pendiente Contrato Servicios Educativos" && (
                      <Link to={`/matricula/${alumno.id}/matricular`}>
                        <Button
                          size="sm"
                          style={{
                            marginTop: "20px",
                            marginRight: "10px",
                          }}
                          color="success"
                        >
                          {t("Descargar Contrato")}
                        </Button>
                      </Link>
                    )}
                </> :
                null
            }
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

TarjetaAlumno.propTypes = {
  t: PropTypes.func.isRequired,
  alumno: PropTypes.shape({}).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  puedeActualizar: PropTypes.bool.isRequired,
  onChangePaymentStatus: PropTypes.func.isRequired,
  onChangeContractStatus: PropTypes.func.isRequired,
  onToggleStudentStatus: PropTypes.func.isRequired,
  puedeCambiarEstadoMatricula: PropTypes.bool.isRequired,
  puedeCambiarEstadoPago: PropTypes.bool.isRequired,
  puedeCambiarEstadoContrato: PropTypes.bool.isRequired,
  loadingSuccessContractChange: PropTypes.bool.isRequired,
};

export default withTranslation("common")(TarjetaAlumno);
